module.exports = {
  "hierarchy": {
    "bottom": [
      "tabPane-bottom"
    ],
    "mobileDetailViewTabs": [
      "tabPane-mobileDetailViewTabs"
    ],
    "detailViewTabs": [
      "tabPane-detailViewTabs"
    ],
    "settingsTabs": [
      "tabPane-detailViewTabs",
      "tabPane-settingsTabs"
    ]
  },
  "props": {
    "default": {},
    "bottom": {},
    "mobileDetailViewTabs": {},
    "detailViewTabs": {},
    "settingsTabs": {}
  }
}