module.exports = {
  "hierarchy": {
    "small": [
      "element-small"
    ]
  },
  "props": {
    "default": {
      "icon": "fa fa-check"
    },
    "small": {
      "icon": "fa fa-check"
    }
  }
}