module.exports = {
  "hierarchy": {
    "hollow": [
      "dateTimePicker-hollow"
    ],
    "test": [
      "dateTimePicker-test"
    ]
  },
  "props": {
    "default": {},
    "hollow": {},
    "test": {}
  }
}