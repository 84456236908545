module.exports = {
  "hierarchy": {
    "small": [
      "list-small"
    ],
    "dataListItem": [
      "list-dataListItem"
    ]
  },
  "props": {
    "default": {
      "icon": "fa fa-check"
    },
    "small": {
      "icon": "fa fa-check"
    },
    "dataListItem": {
      "icon": "fa fa-check"
    }
  }
}