module.exports = {
  "hierarchy": {
    "secondary": [
      "dataBlock-secondary"
    ],
    "primary": [
      "dataBlock-primary"
    ]
  },
  "props": {
    "default": {},
    "secondary": {
      "vertical": false
    },
    "primary": {}
  }
}