module.exports = {
  "hierarchy": {
    "inputTitle": [
      "label-inputTitle"
    ],
    "checkboxTitle": [
      "label-inputTitle",
      "label-checkboxTitle"
    ],
    "inputCaption": [
      "label-inputCaption"
    ],
    "input": [
      "label-inputTitle",
      "label-input"
    ]
  },
  "props": {
    "default": {},
    "inputTitle": {},
    "checkboxTitle": {},
    "inputCaption": {},
    "input": {}
  }
}