module.exports = {
  "hierarchy": {
    "inputGrow": [
      "textInput-inputGrow"
    ]
  },
  "props": {
    "default": {
      "icon": "lv lv-search"
    },
    "inputGrow": {
      "icon": "lv lv-search"
    }
  }
}