module.exports = {
  "hierarchy": {
    "paginationInput": [
      "combobox-paginationInput"
    ]
  },
  "props": {
    "default": {
      "selectIcon": "lv lv-chevron-down"
    },
    "paginationInput": {
      "selectIcon": "lv lv-chevron-down"
    }
  }
}