module.exports = {
  "hierarchy": {
    "iconBig": [
      "toggleButton-iconBig"
    ],
    "iconMedium": [
      "toggleButton-iconBig",
      "toggleButton-iconMedium"
    ],
    "iconSmall": [
      "toggleButton-iconBig",
      "toggleButton-iconMedium",
      "toggleButton-iconSmall"
    ],
    "actions": [
      "toggleButton-actions"
    ],
    "nav": [
      "toggleButton-nav"
    ],
    "navSubItem": [
      "toggleButton-nav",
      "toggleButton-navSubItem"
    ],
    "navCollapsed": [
      "toggleButton-nav",
      "toggleButton-navCollapsed"
    ],
    "navSecondary": [
      "toggleButton-nav",
      "toggleButton-navSecondary"
    ],
    "navSecondaryMobile": [
      "toggleButton-nav",
      "toggleButton-navSecondary",
      "toggleButton-navSecondaryMobile"
    ],
    "mobile": [
      "toggleButton-mobile"
    ],
    "mobileSearch": [
      "toggleButton-mobile",
      "toggleButton-mobileSearch"
    ],
    "mobileDateRange": [
      "toggleButton-mobileDateRange"
    ],
    "selector": [
      "toggleButton-actions",
      "toggleButton-selector"
    ]
  },
  "props": {
    "default": {},
    "iconBig": {},
    "iconMedium": {},
    "iconSmall": {},
    "actions": {
      "iconOff": "lv lv-chevron-down",
      "iconOn": "lv lv-chevron-up"
    },
    "nav": {},
    "navSubItem": {},
    "navCollapsed": {},
    "navSecondary": {},
    "navSecondaryMobile": {},
    "mobile": {},
    "mobileSearch": {},
    "mobileDateRange": {},
    "selector": {
      "iconOff": "lv lv-chevron-down",
      "iconOn": "lv lv-chevron-up"
    }
  }
}