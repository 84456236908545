module.exports = {
  "hierarchy": {
    "mainScreenMode": [
      "popup-mainScreenMode"
    ]
  },
  "props": {
    "default": {
      "icon": "fa fa-check"
    },
    "mainScreenMode": {
      "icon": "fa fa-check"
    }
  }
}